<template>
  <CommonUModal
    :show="showPreview"
    :enable-background-close="enableBackgroundClose"
    :open="showPreview"
    :hide-close="false"
    @close="emit('close')"
  >
    <div class="flex flex-col justify-center gap-y-3 rounded-lg bg-white">
      <div class="flex w-full flex-row justify-between bg-[#FAFAFA] p-4">
        <div class="h3">Custom Design</div>
        <IconsXIcon
          @click="emit('close')"
          class="cursor-pointer"
          :fill-color="'#212121'"
        />
      </div>
      <div class="p-4 text-center">
        <div class="flex items-center justify-center">
          <CommonUImg
            v-if="isFrontSide"
            class="h-64 w-64 border border-gray-300"
            img-classess="!object-contain"
            alt="custom design preview front"
            :src="product?.custom_fields?.front_image"
          />
          <CommonUImg
            v-if="product?.custom_fields.back_image != '' && !isFrontSide"
            class="h-64 w-64 border border-gray-300"
            img-classess="!object-contain"
            alt="custom design preview back"
            :src="product?.custom_fields?.back_image"
          />
        </div>
        <div
          v-if="product?.custom_fields.back_image != ''"
          class="mt-4 flex w-full flex-row items-center rounded-lg bg-[#f9f9f9] p-1"
        >
          <button
            class="block w-1/2 p-2"
            :class="
              isFrontSide
                ? 'rounded-lg border border-[#E3E3E3] bg-white'
                : 'rounded-none bg-transparent'
            "
            @click="isFrontSide = true"
          >
            Front Side
          </button>
          <button
            class="block w-1/2 p-2"
            :class="
              !isFrontSide
                ? 'rounded-lg border border-[#E3E3E3] bg-white'
                : 'rounded-none bg-transparent'
            "
            @click="switchSide"
          >
            Back Side
          </button>
        </div>
      </div>
    </div>
  </CommonUModal>
</template>

<script setup lang="ts">
const emit = defineEmits(["close"]);
const props = defineProps({
  showPreview: {
    type: Boolean,
    default: false,
  },
  product: {
    type: Object,
  },
  enableBackgroundClose: {
    type: Boolean,
    default: false,
  },
});

const isFrontSide = ref(true);

const switchSide = () => {
  isFrontSide.value = !isFrontSide.value;
};
</script>
